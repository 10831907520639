<template>
    <div class="row">
        <div class="col-md-12 col-xl-12">
            <el-row :gutter="20" class="mb-g search-thong-bao">
                <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="4" class="mt-2">
                    <el-input placeholder="Nhập từ khóa muốn tìm" size="medium" v-model="form.category" ></el-input>
                </el-col>
                <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="4" class="mt-2">
                  <el-select placeholder="Nhập từ khóa muốn tìm"
                             size="medium" v-model="form.is_special"
                             filterable
                             clearable
                             class="w-100"
                  >
                    <el-option v-for="(item, key) in filterSpecial" :key="key" :label="item.label" :value="item.value">

                    </el-option>
                  </el-select>
                </el-col>
                <el-col :xs="24" :sm="8 " :md="4" :lg="3" :xl="2"  class="mt-2">
                    <el-button class="w-100" type="primary" @click="searchTopic" icon="el-icon-search">Tìm kiếm</el-button>
                </el-col>
            </el-row>
            <el-skeleton  :loading="loading" animated>
                <!--eslint-disable-->
                <template slot="template">
                    <el-skeleton-item variant="div" style="width: 100%; height: 48px; margin-bottom: 1.5rem" />
                    <el-skeleton-item variant="div" style="width: 100%; height: 48px; margin-bottom: 1.5rem" />
                    <el-skeleton-item variant="div" style="width: 100%; height: 48px; margin-bottom: 1.5rem" />
                    <el-skeleton-item variant="div" style="width: 100%; height: 48px; margin-bottom: 1.5rem" />
                    <el-skeleton-item variant="div" style="width: 100%; height: 48px; margin-bottom: 1.5rem" />
                    <el-skeleton-item variant="div" style="width: 100%; height: 48px; margin-bottom: 1.5rem" />
                    <el-skeleton-item variant="div" style="width: 100%; height: 48px; margin-bottom: 1.5rem" />
                    <el-skeleton-item variant="div" style="width: 100%; height: 48px; margin-bottom: 1.5rem" />
                </template>
                <template>
                    <el-empty v-if="data.length === 0" :description="form.category ? `Không tìm thấy kết quả nào!` : 'Chưa có dữ liệu cẩm nang nào!'"></el-empty>

                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card mb-g border shadow-0" v-if="data.length > 0">
                                <div class="card-body p-0">
                                    <div class="row no-gutters row-grid">
                                        <!-- thread -->
                                        <notification-item v-on:action-open="openLink(item)" v-for="(item, index) in data" :item="item" :key="index" ></notification-item>
                                        <!-- thread -end -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </el-skeleton>
        </div>
        <!--eslint-disable-->
        <el-pagination
                v-if="paging.total > 10"
                background
                @current-change="handleCurrentChange"
                :current-page.sync="paging.current_page"
                layout="prev, pager, next"
                :page-size="paging.per_page"
                :total="paging.total"
        >
        </el-pagination>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import {INFORMATION_INFORMATION} from "@/core/services/store/service.module";
    import NotificationItem from "@/views/pages/information/thong-bao-item";
    import {filterSpecial} from "@/core/config/informationOption";
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);

    export default {
        name: "thong-bao",
        components: {
            NotificationItem
        },
        data() {
            return {
                data: [],
                form: {
                    category: null,
                    startDate: null,
                    endDate: null,
                    is_special: ''
                },
                loading: false,
                isFocus: false,
                filterSpecial,
                notification: {
                    title: null
                },
                paging: {
                    current_page: 1,
                    per_page: 50,
                    total: 0,
                },
                currentPage: 1,
                dialogFormVisible: false,
            }
        },
        methods:{
            handleCurrentChange(val) {
                this.currentPage = val;
                this.queryServer({page: val});
            },
            queryServer(customProperties) {
                this.loading = true;
                let params = {};
                params = _.merge(params, customProperties);
                this.$store.dispatch(INFORMATION_INFORMATION, params)
                    .then((response) => {
                        if (response.status === 422) {
                            this.loading = false;
                            this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                        } else {
                            this.loading = false;
                            this.data = response.data;
                            this.paging = response.pagination
                        }
                    })
            },
            searchTopic: function () {
                let params = {...this.form};
                this.queryServer(params);
            },
            openLink(item) {
                this.dialogFormVisible = true;
                this.notification = item;
                this.$router.push({
                    name: 'information-notification-slug',
                    params: { slug: item.slug }
                });
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Thông tin", icon: 'far fa-info-square'},
                {title: "Thông báo", icon: 'far fa-info-square'}
            ]);
            this.queryServer();
        }
    }
</script>
