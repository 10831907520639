<template>
    <div class="col-12">
        <div class="row no-gutters row-grid align-items-stretch">
            <div class="col-md">
                <div class="p-3">
                    <div class="d-flex">
                        <div class="d-inline-flex flex-column">
                            <el-link class="fs-lg fw-500 d-block text-primary"  @click="openLink()" :underline="false" type="primary" icon="el-icon-link">
                                {{ item.category || 'Chưa có thông tin' }}
                                <span class="badge"
                                      :class="{
                                    'badge badge-warning rounded': isNew ,
                                    'badge-success rounded': isUpdate
                                }"
                                >
                                    {{ isNew ? "Mới" : (isUpdate ? 'Cập nhật' : '')}}</span> <span class="badge badge-success" v-if="item.is_special">Nổi bật</span>

                            </el-link>
                            <div class="d-block text-muted fs-sm">
                                Thời gian áp dụng: Từ <b>{{ $moment(item.start_date).format('DD-MM-YYYY') }} {{ item.end_date ? `đến ${ $moment(item.end_date).format('DD-MM-YYYY') }` : '' }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8 col-md-3 hidden-md-down">
                <div class="p-3 p-md-3">
                    <div class="d-flex align-items-center">
                        <div class="flex-1 min-width-0">
                            <a href="javascript:void(0)" class="d-block text-truncate cursor-text">
                                Cập nhật lần cuối: {{ $moment(item.updated_at).format('DD-MM-YYYY') }}
                            </a>
                            <div class="text-muted small text-truncate">
                                Thời gian áp dụng: Từ <b>{{ $moment(item.start_date).format('DD-MM-YYYY') }} {{ item.end_date ? `đến ${ $moment(item.end_date).format('DD-MM-YYYY') }` : '' }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "thong-bao-item",
        props: {
            item: Object
        },
        data() {
            return {
                isNew: false,
                isUpdate: false,
            }
        },
        methods:{
            openLink() {
                this.$emit('action-open')
            }
        },
        mounted() {
            let updated = this.$moment(this.item.updated_at).add(6, 'days').format('YYYY-MM-DD');
            let created = this.$moment(this.item.created_at).add(6, 'days').format('YYYY-MM-DD');
            let current = this.$moment().format('YYYY-MM-DD');
            if (current <= created) {
                this.isNew = true
            } else {
                if (current <= updated) {
                    this.isUpdate = true
                }
            }
        }
    }
</script>
